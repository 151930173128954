<template>
    <base-material-chart-card
        :loading="loading"
        :options="options"
        :series="series"
        height="300px"
        type="donut"
    >
        <template v-slot:heading>
            <h4 class="card-title font-weight-light">
                Cateogorii de licitatii (toate)
            </h4>
        </template>
        <template v-slot:actions>
            <v-icon class="mr-1" small>
                mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">
                <template v-if="updated">
                    actualizat {{ localeDate(updated) }}
                </template>
            </span>
        </template>
    </base-material-chart-card>
</template>

<script>
export default {
    name: "AllAuctionsOnCategoriesPieChart",
    data() {
        return {
            loading: false,
            series: [],
            options: {},
            updated: ""
        };
    },
    methods: {
        localeDate(date) {
            return this.$d(new Date(date), "long");
        }
    },
    mounted() {
        this.loading = true;
        this.$admin.http
            .get("api/auction/stats/by-category")
            .then(({ data }) => {
                this.options = data.options;
                this.series = data.series;
                this.updated = data.updated;
                this.loading = false;
            });
    }
};
</script>

<style scoped></style>
